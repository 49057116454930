import { Editor } from "@tiptap/react";
import {
	Stack,
	StackDivider,
	StackProps,
	useColorModeValue,
} from "@chakra-ui/react";
import FormatButton from "@components/wysiwyg/format-button";
import FormatGroup from "@components/wysiwyg/format-group";
import { brandColor } from "@definitions/chakra/theme/foundations/colors";
import {
	BiBold,
	BiItalic,
	BiUnderline,
	BiStrikethrough,
	BiPen,
	BiCode,
	BiLink,
	BiUnlink,
	BiListOl,
	BiListUl,
	BiUndo,
	BiRedo,
} from "react-icons/bi";

interface MenuBarProps {
	editor: Editor | null;
}

function MenuBar({ editor }: MenuBarProps) {
	if (!editor) return null;

	const borderColor = useColorModeValue("gray.200", "gray.300");
	return (
		<Stack
			wrap="wrap"
			direction="row"
			borderBottom={`1px solid`}
			borderColor={borderColor}
			divider={<StackDivider bg={borderColor} w={0.5} ml={2} mr={3} h={5} />}
		>
			<FormatGroup>
				<FormatButton
					label="Bold (Ctrl+B)"
					icon={BiBold}
					onClick={() => editor.chain().focus().toggleBold().run()}
					isActive={editor.isActive("bold")}
				/>
				<FormatButton
					label="Italic (Ctrl+U)"
					icon={BiItalic}
					onClick={() => editor.chain().focus().toggleItalic().run()}
					isActive={editor.isActive("italic")}
				/>
				{/*<FormatButton*/}
				{/*  label="Underline (Ctrl+I)"*/}
				{/*  icon={BiUnderline}*/}
				{/*  onClick={() => editor.chain().focus().togg().run()}*/}
				{/*  isActive={editor.isActive("underline")}*/}
				{/*/>*/}
				<FormatButton
					label="Strikethrough"
					icon={BiStrikethrough}
					onClick={() => editor.chain().focus().toggleStrike().run()}
					isActive={editor.isActive("strike")}
				/>

				{/*<FormatButton*/}
				{/*  label="Highlight"*/}
				{/*  icon={BiPen}*/}
				{/*  onClick={() => editor.chain().focus().toggleHighlight().run()}*/}
				{/*  isActive={editor.isActive("highlight")}*/}
				{/*/>*/}
			</FormatGroup>
			<FormatGroup>
				<FormatButton
					label="Inline Code"
					icon={BiCode}
					onClick={() => editor.chain().focus().toggleCode().run()}
					isActive={editor.isActive("code")}
				/>
			</FormatGroup>
			<FormatGroup>
				<FormatButton
					label="Link"
					icon={BiLink}
					onClick={(e: any) => {
						e.preventDefault();
						const href = window?.prompt("URL") as string;
						editor
							.chain()
							.focus()
							.extendMarkRange("link")
							.setLink({ href })
							.run();
					}}
					isActive={editor.isActive("link")}
				/>

				{editor.isActive("link") && (
					<FormatButton
						label="Remove Link"
						icon={BiUnlink}
						onClick={() => editor.chain().focus().unsetLink().run()}
					/>
				)}
			</FormatGroup>
			<FormatGroup>
				<FormatButton
					label="Bulleted List"
					icon={BiListUl}
					onClick={() => editor.chain().focus().toggleBulletList().run()}
					isActive={editor.isActive("bulletList")}
				/>
				<FormatButton
					label="Numbered List"
					icon={BiListOl}
					onClick={() => editor.chain().focus().toggleOrderedList().run()}
					isActive={editor.isActive("orderedList")}
				/>
			</FormatGroup>
			{/*<FormatGroup>*/}
			{/*  <FormatButton*/}
			{/*    label="Large Heading (Ctrl+[)"*/}
			{/*    icon={IconFormatHeading}*/}
			{/*    onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}*/}
			{/*    isActive={editor.isActive("heading", { level: 2 })}*/}
			{/*  />*/}
			{/*  <FormatButton*/}
			{/*    label="Small Heading (Ctrl+])"*/}
			{/*    icon={IconFormatHeading}*/}
			{/*    isSmall*/}
			{/*    onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}*/}
			{/*    isActive={editor.isActive("heading", { level: 3 })}*/}
			{/*  />*/}
			{/*</FormatGroup>*/}
			{/*<FormatGroup>*/}
			{/*  <FormatButton*/}
			{/*    label="Block Quote"*/}
			{/*    icon={IconFormatQuote}*/}
			{/*    onClick={() => editor.chain().focus().toggleBlockquote().run()}*/}
			{/*    isActive={editor.isActive("blockquote")}*/}
			{/*  />*/}
			{/*  <FormatButton*/}
			{/*    label="Code Block"*/}
			{/*    icon={IconFormatCodeBlock}*/}
			{/*    onClick={() => editor.chain().focus().toggleCodeBlock().run()}*/}
			{/*    isActive={editor.isActive("codeBlock")}*/}
			{/*  />*/}
			{/*</FormatGroup>*/}
			{/*<FormatGroup>*/}
			{/*  <FormatButton*/}
			{/*    label="Hard Break"*/}
			{/*    icon={IconFormatWrapText}*/}
			{/*    onClick={() => editor.chain().focus().setHardBreak().run()}*/}
			{/*  />*/}
			{/*  <FormatButton*/}
			{/*    label="Clear Format"*/}
			{/*    icon={IconFormatClear}*/}
			{/*    onClick={() => editor.chain().focus().clearNodes().unsetAllMarks().run()}*/}
			{/*  />*/}
			{/*</FormatGroup>*/}
			{/*<FormatGroup>*/}
			{/*  {withImage && (*/}
			{/*    <FormatButton*/}
			{/*      type="image"*/}
			{/*      label="Add an image"*/}
			{/*      icon={IconFormatImage}*/}
			{/*      onClick={(event: File) => setImage(event)}*/}
			{/*    />*/}
			{/*  )}*/}
			{/*</FormatGroup>*/}
			<FormatGroup>
				<FormatButton
					onClick={() => editor.chain().focus().undo().run()}
					label="Undo"
					icon={BiUndo}
				/>
				<FormatButton
					label="Redo"
					icon={BiRedo}
					onClick={() => editor.chain().focus().redo().run()}
				/>
			</FormatGroup>
		</Stack>
	);
}
export default MenuBar;
