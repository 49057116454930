import {
	Box,
	IconButton,
	Tooltip,
	useColorModeValue,
	useToast,
	VisuallyHidden,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { MouseEventHandler, useState } from "react";
import { IconType } from "react-icons";
import { brandColor } from "@definitions/chakra/theme/foundations/colors";

interface FormatButtonProps {
	label: string;
	icon: IconType;
	isSmall?: boolean;
	onClick?: MouseEventHandler<HTMLButtonElement> | ((t: File) => void);
	isActive?: boolean;
	type?: string;
}

function FormatButton({
	label,
	type,
	icon,
	isSmall = false,
	onClick,
	isActive,
}: FormatButtonProps) {
	const toast = useToast();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const color = useColorModeValue(brandColor[0], "whiteAlpha.900");
	const bgColor = useColorModeValue("whiteAlpha.900", brandColor[0]);

	const Icon = icon;

	const onDropImage = async (files: File[]) => {
		const [image] = files;

		try {
			setIsSubmitting(true);

			if (image) {
				console.log({ image });
				if (image.size >= 5000000) {
					throw new Error("Size of the image must be below 5MB.");
				} else {
					(onClick as unknown as (t: File) => void)?.(image);
				}
			}

			setIsSubmitting(false);
		} catch (error: any) {
			toast({
				title: "Avatar size limit error",
				description: error.message,
				status: "error",
				isClosable: true,
			});
			setIsSubmitting(false);
		}
	};

	const { getInputProps, getRootProps } = useDropzone({
		multiple: false,
		accept: {
			"image/jpeg": [".jpeg"],
			"image/png": [".png"],
			"image/jpg": [".jpg"],
			"image/webp": [".webp"],
			"image/gif": [".gif"],
		},
		disabled: isSubmitting,
		onDrop: onDropImage,
	});

	if (type === "image") {
		return (
			<Tooltip
				label={label}
				aria-label={label}
				fontSize="xs"
				placement="top"
				hasArrow
			>
				<IconButton
					rounded="sm"
					mr={1}
					bg={isActive ? color : "transparent"}
					border="none"
					p={1}
					aria-label={label}
					boxSize={7}
					h={9}
					color={isActive ? bgColor : color}
					_hover={{
						bg: color,
						color: bgColor,
					}}
					_focus={{ outline: "none" }}
					transition="var(--transition)"
					icon={
						<Box
							as={Icon}
							boxSize={isSmall ? 4 : 5}
							mt={isSmall ? "0.5" : undefined}
						/>
					}
				>
					<VisuallyHidden>{label}</VisuallyHidden>
					<Box {...getRootProps()}>
						<Box as="input" {...getInputProps()} />
					</Box>
				</IconButton>
			</Tooltip>
		);
	}

	return (
		<Tooltip
			label={label}
			aria-label={label}
			fontSize="xs"
			placement="top"
			hasArrow
		>
			<IconButton
				rounded="sm"
				mr={1}
				bg={isActive ? color : "transparent"}
				border="none"
				p={1}
				aria-label={label}
				boxSize={7}
				h={9}
				color={isActive ? bgColor : color}
				_hover={{
					bg: color,
					color: bgColor,
				}}
				_focus={{ outline: "none" }}
				onClick={
					onClick as unknown as React.MouseEventHandler<HTMLButtonElement>
				}
				transition="var(--transition)"
				icon={
					<Box
						as={Icon}
						boxSize={isSmall ? 4 : 5}
						mt={isSmall ? "0.5" : undefined}
					/>
				}
			>
				<VisuallyHidden>{label}</VisuallyHidden>
				<Box
					as={Icon}
					boxSize={isSmall ? 4 : 5}
					mt={isSmall ? "0.5" : undefined}
				/>
			</IconButton>
		</Tooltip>
	);
}

export default FormatButton;
