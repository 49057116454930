import { useEditor, EditorContent, HTMLContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import MenuBar from "@components/wysiwyg/menu-bar";
import React, { FC, useCallback, useEffect, useState } from "react";
import debounce from "@utils/debounce";

interface TiptapEditorProps {
	value?: string;
	onChange?: (value: string) => void;
	onBlur?: (value: string) => void;
	disabled?: boolean;
}

const TiptapEditor: FC<TiptapEditorProps> = ({
	value,
	onChange,
	onBlur: onEditorBlur,
	disabled,
}) => {
	const [menuBarVisible, setMenuBarVisible] = useState(false);

	const editor = useEditor({
		extensions: [StarterKit, Link],
		content: value,
		onUpdate({ editor }) {
			onChange && onChange(editor.getHTML());
		},
		onBlur({ editor }) {
			onEditorBlur && onEditorBlur(editor.getHTML());
		},
		editable: !disabled,
	});

	useEffect(() => {
		editor?.commands?.setContent(value as HTMLContent, true, {
			preserveWhitespace: "full",
		});
	}, [!!value]);

	const toggleMenu = (val: boolean) => setMenuBarVisible(val);

	const toggleMenuDebounced = useCallback(debounce(toggleMenu, 100), []);

	const borderColor = useColorModeValue("gray.200", "gray.300");

	return (
		<Flex
			border={`1px solid`}
			borderColor={borderColor}
			_focus={{ border: `2px solid ${borderColor}` }}
			direction="column"
			maxH="md"
			rounded="md"
		>
			<Box h={menuBarVisible ? "unset" : 0} overflow={"hidden"}>
				<MenuBar editor={editor} />
			</Box>
			<Box py={2} px={4} flex="1 1 auto" overflowX="hidden" overflowY="auto">
				<EditorContent
					editor={editor}
					onFocus={() => toggleMenuDebounced(true)}
					onBlur={() => toggleMenuDebounced(false)}
				/>
			</Box>
		</Flex>
	);
};

export default TiptapEditor;
