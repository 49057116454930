import React, { FC } from "react";
import {
	Divider,
	DividerProps,
	HStack,
	StackProps,
	Text,
	TextProps,
} from "@chakra-ui/react";

interface SectionHeaderProps {
	label: string;
	wrapperProps?: StackProps;
	textProps?: TextProps;
	dividerProps?: DividerProps;
}

const SectionHeader: FC<SectionHeaderProps> = ({
	label,
	wrapperProps = {},
	textProps = {},
	dividerProps = {},
}) => {
	return (
		<HStack align={"center"} px={2} py={4} {...wrapperProps}>
			<Text whiteSpace={"nowrap"} m={0} color={"brand.600"} {...textProps}>
				{label}
			</Text>
			<Divider
				orientation="horizontal"
				borderColor={"brand.600"}
				{...dividerProps}
			/>
		</HStack>
	);
};

export default SectionHeader;
