import React from "react";
import {
	FormControl,
	FormErrorMessage,
	FormHelperText,
	FormLabel,
	Heading,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { Controller, Control } from "react-hook-form";
import DirtyFieldHelperText from "@components/form/dirty-field-helper-text/dirty-field-helper-text";

interface IOption {
	label: string;
	value: string | number | null;
	data?: unknown;
	component?: React.ReactElement;
}

interface FormSelectProps {
	name: string;
	control: Control<any, any>;
	options: IOption[];
	label?: string;
	placeholder?: string;
	disabled?: boolean;
	isClearable?: boolean;
	shouldShowUnsavedWarning?: boolean;
}

const FormSelect: React.FC<FormSelectProps> = ({
	label,
	name,
	disabled,
	control,
	options,
	placeholder,
	isClearable,
	shouldShowUnsavedWarning,
}) => {
	return (
		<Controller
			control={control}
			name={name}
			render={({
				field: { onChange, onBlur, value, name, ref },
				fieldState: { invalid, error, isDirty },
			}) => (
				<FormControl isInvalid={invalid}>
					<FormLabel htmlFor={name}>
						<Heading size={"sm"}>{label}</Heading>
					</FormLabel>
					<Select
						ref={ref}
						id={name}
						name={name}
						options={options}
						placeholder={placeholder}
						onChange={onChange}
						onBlur={onBlur}
						value={value}
						isDisabled={disabled}
						isClearable={isClearable}
					/>
					{shouldShowUnsavedWarning && isDirty && <DirtyFieldHelperText />}
					{!!error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
				</FormControl>
			)}
		/>
	);
};
export default FormSelect;
