const selectKeys = {
	contractType: "ContractType",
	levelOfExperience: "LevelExperience",
	jobEducationNeeds: "JobEducationNeeds",
	jobTitleSelect: "JobTitleSelect",
	companySize: "CompanySize",
	positions: "Positions",
	employer: "Employer",
	industry: "Industry",
	activities: "Activity",
	desk: "Desk",
	language: "Language",
	activity: (industryId: number | string) => `Industry/${industryId}/Activity`,
} as const;

export type SelectKeyTypes = keyof typeof selectKeys;
export type SelectValueTypes = typeof selectKeys[SelectKeyTypes];

export default selectKeys;
