import { Flex, StackProps } from "@chakra-ui/react";

function FormatGroup({ children }: StackProps) {
	return (
		<Flex experimental_spaceX="0.5" px={2} h="full" align="center">
			{children}
		</Flex>
	);
}

export default FormatGroup;
