import React, { FC } from "react";
import {
	FormControl,
	FormErrorMessage,
	FormHelperText,
	FormLabel,
	Heading,
} from "@chakra-ui/react";
import { Control } from "react-hook-form";
import { Controller } from "react-hook-form";
import TiptapEditor from "@components/wysiwyg/tiptap-editor";
import DirtyFieldHelperText from "@components/form/dirty-field-helper-text/dirty-field-helper-text";

interface FormWysiwygInputProps {
	name: string;
	control: Control<any, any>;
	label?: string;
	disabled?: boolean;
	shouldShowUnsavedWarning?: boolean;
}

const FormWysiwygInput: FC<FormWysiwygInputProps> = ({
	label,
	name,
	control,
	disabled,
	shouldShowUnsavedWarning,
}) => {
	return (
		<>
			<Controller
				control={control}
				name={name}
				render={({
					field: { onChange, onBlur, value, name },
					fieldState: { invalid, error, isDirty },
				}) => (
					<FormControl isInvalid={invalid}>
						<FormLabel htmlFor={name}>
							<Heading size={"sm"}>{label}</Heading>
						</FormLabel>
						<TiptapEditor
							onChange={onChange}
							onBlur={onBlur}
							value={value}
							disabled={disabled}
						/>
						{shouldShowUnsavedWarning && isDirty && <DirtyFieldHelperText />}
						{!!error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
					</FormControl>
				)}
			/>
		</>
	);
};

export default FormWysiwygInput;
