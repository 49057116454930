/* README
 * This hook is a wrapper around react-hook-form's useForm https://react-hook-form.com/api/useform.
 * It is used to keep track of un-submitted changes in forms, read/write those changes in local storage and clear them when submitted
 * */

import {
	FieldValues,
	UseFormProps,
	UseFormReturn,
} from "react-hook-form/dist/types";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { readFromStorage, writeToStorage } from "@utils/local-storage";
import { LSFormDrafts } from "@definitions/local-storage.keys";
import { FieldNamesMarkedBoolean } from "react-hook-form/dist/types/form";
import { FieldPath } from "react-hook-form/dist/types/path";

export interface UseFormDraftProps<TFieldValues, TContext>
	extends UseFormProps<TFieldValues, TContext> {
	key: string;
}
function useFormDraft<
	TFieldValues extends FieldValues = FieldValues,
	TContext = any,
>(
	props: UseFormDraftProps<TFieldValues, TContext>,
): UseFormReturn<TFieldValues, TContext> & { removeFormDraft: () => void } {
	const { key, ...useFormProps } = props;
	const {
		getValues,
		setValue,
		reset,
		watch,
		formState: { isDirty, dirtyFields, isSubmitSuccessful, ...formState },
		...formReturns
	} = useForm<TFieldValues, TContext>(useFormProps);

	const watchForm = watch();

	useEffect(() => {
		readFromDraft();
	}, []);

	useEffect(() => {
		isDirty && saveFormState();
	}, [watchForm]);

	useEffect(() => {
		isSubmitSuccessful && removeFormDraft();
	}, [isSubmitSuccessful]);

	const getDrafts = () => {
		return readFromStorage(LSFormDrafts) || {};
	};

	const saveFormState = () => {
		writeToStorage(LSFormDrafts, {
			...getDrafts(),
			[key]: { values: getValues(), dirtyFields },
		});
	};

	const readFromDraft = () => {
		const drafts = getDrafts();
		const draft = drafts[key];
		if (draft) {
			const dirtyFields =
				draft.dirtyFields as FieldNamesMarkedBoolean<TFieldValues>;
			for (const dirtyFieldKey in dirtyFields) {
				setValue(
					dirtyFieldKey as unknown as FieldPath<TFieldValues>,
					draft.values[dirtyFieldKey],
					{
						shouldDirty: true,
					},
				);
			}
		}
	};

	const removeFormDraft = () => {
		const drafts = getDrafts();
		delete drafts[key];
		writeToStorage(LSFormDrafts, drafts);
		reset(getValues());
	};

	return {
		removeFormDraft,
		getValues,
		setValue,
		watch,
		reset,
		formState: { isDirty, dirtyFields, isSubmitSuccessful, ...formState },
		...formReturns,
	};
}

export default useFormDraft;
